export const environment = {
  production: false,
  NX_PUBLIC_VERSION: `1.0.${process.env.NX_PUBLIC_VERSION ?? 0}`,
  NX_PUBLIC_BRANCH: process.env.NX_PUBLIC_BRANCH,
  NX_DICOM_MAX_FILE_SIZE_BYTES: '3221225472',
  NX_BUILD_NUMBER: process.env.NX_PUBLIC_VERSION ?? 0,

  NX_SERVER_GQL: 'https://api-workflow-vnv.test-executivedrive.com/graphql',
  NX_AUTH_REDIRECT_URI: 'https://dps-vnv.myaprevo.com/code',
  NX_CYBORG_ENDPOINT: 'https://cyborg-vnv.test-executivedrive.com',
  NX_CLIENT_URL: 'https://dps-vnv.myaprevo.com',
  NX_CLIENT_ID: '45a6a608-45d8-48c9-bee0-5b3f7916a782',

  NX_AUTH_METHOD: 'OAUTH',
  NX_AUTH_URL: 'https://cm-idp-vnv.auth.us-west-2.amazoncognito.com/login',
  NX_OAUTH_URL: 'https://cm-idp-vnv.auth.us-west-2.amazoncognito.com',
  NX_OAUTH_IDP_JUMPCLOUD: 'JumpCloud-VNV',
  NX_OAUTH_IDP_GOOGLE: 'Google',
  NX_AUTH_CLIENT_ID: '5s0frishclf7t881jbkpn5bvo4',
  NX_AUTH_RESPONSE_TYPE: 'token',
  NX_AUTH_SCOPE: 'email+openid+phone+profile',

  NX_MIXPANEL_TOKEN: 'ba2586bd067b7beec988c5f9590be933',
  NX_ONESIGNAL_APP_ID: '194548bd-852b-4939-ae24-f5b37aa12b66',
  NX_ONESIGNAL_ENABLED: false,

  NX_FF_ALIFX_ORIENTATION: true,
  NX_FF_TLIFC_ORIENTATION: true,
  NX_FF_AUTO_CORRECT: true,
  NX_FF_DASHBOARD_PRODUCTION_CALENDAR: true,
  NX_FF_M4_INSERTER_INVENTORY_MANAGEMENT: true,
  NX_FF_M4L_EXPANDED_INVENTORY_AVAILABLE: true,
  NX_FF_DESIGN_REQUIRE_TEM_013: true,
  NX_FF_MAGIC_LINKS: true,

  NX_ENVIRONMENT: 'vnv',
};
