import {
  CaseReportStatusType,
  LordosisDifferenceRationaleType,
  PathologyType,
  PlanFeedbackType,
  SegmentationImagingAssessmentType,
  SegmentationSliceThicknessType,
  SegmentationType,
  Statement,
  StatementType,
} from '@workflow-nx/common';

export const formatSegmentationImagingAssessmentType = (
  segmentationImagingAssessmentType?: SegmentationImagingAssessmentType,
): string => {
  let formattedSegmentationImagingAssessmentType = 'Invalid Segmentation Imaging Assessment Type';
  if (!segmentationImagingAssessmentType) {
    return 'formattedSegmentationImagingAssessmentType';
  }

  switch (segmentationImagingAssessmentType) {
    case SegmentationImagingAssessmentType.GoldCtFullLengthXrayWithFemoralHeads:
      formattedSegmentationImagingAssessmentType =
        'Gold CT Full Length Xray With Femoral Heads (50% or greater and overlapping)';
      break;
    case SegmentationImagingAssessmentType.SilverCtStandardLumbarXrayWithFemoralHeads:
      formattedSegmentationImagingAssessmentType =
        'Silver CT Standard Lumbar Xray With Femoral Heads (50% or greater and overlapping)';
      break;
    case SegmentationImagingAssessmentType.BronzeCtStandardLumbarXrayWithoutFemoralHeads:
      formattedSegmentationImagingAssessmentType =
        'Bronze CT Standard Lumbar Xray Without Femoral Heads';
      break;
    case SegmentationImagingAssessmentType.BronzeCtNoXrayOrUnusableXray:
      formattedSegmentationImagingAssessmentType = 'Bronze CT No Xray Or Unusable Xray';
      break;
  }
  return formattedSegmentationImagingAssessmentType;
};

export const formatCaseReportStatusType = (caseReportStatusType: CaseReportStatusType): string => {
  let formattedCaseReportStatusType = '';
  switch (caseReportStatusType) {
    case CaseReportStatusType.Approved:
      formattedCaseReportStatusType = 'Approved';
      break;
    case CaseReportStatusType.InProgress:
      formattedCaseReportStatusType = 'In-progress';
      break;
    case CaseReportStatusType.InReview:
      formattedCaseReportStatusType = 'In-review';
      break;
    case CaseReportStatusType.Rejected:
      formattedCaseReportStatusType = 'Rejected';
      break;
  }
  return formattedCaseReportStatusType;
};

export const formatCasePathologyType = (casePathologyType: PathologyType): string => {
  let formattedCasePathologyType = '';
  switch (casePathologyType) {
    case PathologyType.Osteomyelitis:
      formattedCasePathologyType = 'Osteomyelitis';
      break;
    case PathologyType.DiscDegeneration:
      formattedCasePathologyType = 'Disc Degeneration';
      break;
    case PathologyType.AdultDegenerativeScoliosis:
      formattedCasePathologyType = 'Adult Degenerative Scoliosis';
      break;
    case PathologyType.AdjacentSegmentDisease:
      formattedCasePathologyType = 'Adjacent Segment Disease';
      break;
    case PathologyType.Spondylolisthesis:
      formattedCasePathologyType = 'Spondylolisthesis';
      break;
    case PathologyType.TransitionalAnatomy:
      formattedCasePathologyType = 'Transitional Anatomy';
      break;
    case PathologyType.ExtraVertebrae:
      formattedCasePathologyType = 'Extra Vertebrae';
      break;
    case PathologyType.EndplateDeformityAtTreatmentLevels:
      formattedCasePathologyType = 'Endplate Deformity at treatment levels';
      break;
    case PathologyType.UniqueAnatomy:
      formattedCasePathologyType = 'Unique Anatomy';
      break;
    case PathologyType.RevisionOfInterbodyImplant:
      formattedCasePathologyType = 'Revision of interbody implant';
      break;
    case PathologyType.RevisionOfOtherPriorHardware:
      formattedCasePathologyType = 'Revision of other prior hardware';
      break;
    case PathologyType.Other:
      formattedCasePathologyType = 'Other';
      break;
  }
  return formattedCasePathologyType;
};

export const formatLordosisDifferenceRationaleType = (
  lordosisDifferenceRationaleType: LordosisDifferenceRationaleType,
): string => {
  let formattedLordosisDifferenceRationaleType = '';
  switch (lordosisDifferenceRationaleType) {
    case LordosisDifferenceRationaleType.HypermobilityInDiscSpaces:
      formattedLordosisDifferenceRationaleType = 'Hypermobility in disc spaces';
      break;
    case LordosisDifferenceRationaleType.XrayQualityCausingInaccurateS1OrL1Placement:
      formattedLordosisDifferenceRationaleType = 'XR quality causing inaccurate S1 or L1 placement';
      break;
    case LordosisDifferenceRationaleType.XrayQualityCausingInaccurateFemoralHeadPlacement:
      formattedLordosisDifferenceRationaleType =
        'XR quality causing inaccurate femoral head placement';
      break;
    case LordosisDifferenceRationaleType.Other:
      formattedLordosisDifferenceRationaleType = 'Other';
      break;
  }
  return formattedLordosisDifferenceRationaleType;
};

export const formatStatementType = (statementType: StatementType): string => {
  let formattedStatementType = '';
  switch (statementType) {
    case StatementType.CorrectionPlanning:
      formattedStatementType = 'Correction Planning';
      break;
    case StatementType.ImplantDesign:
      formattedStatementType = 'Implant Design';
      break;
  }
  return formattedStatementType;
};

export const formatCaseStatementType = (caseStatementType: Statement): string => {
  let formattedCaseStatementType = '';
  switch (caseStatementType) {
    case Statement.DiscHeightsReasonable:
      formattedCaseStatementType = 'Are the anterior disc heights reasonable for each level?';
      break;
    case Statement.AlifxScrewTrajectoriesAcceptable:
      formattedCaseStatementType = 'Are all ALIF-X screw trajectories acceptable?';
      break;
    case Statement.TlifCKyphotic:
      formattedCaseStatementType = 'Are TLIF/C cages kyphotic?';
      break;
    case Statement.CageFootprintFit:
      formattedCaseStatementType =
        'Does case footprint fit the patient anatomy, surgeon preferences or match standard stock sizes?';
      break;
    case Statement.VertebralBodiesEdited:
      formattedCaseStatementType =
        'Were vertebral bodies edited to enhance implant features during implant design?';
      break;
  }
  return formattedCaseStatementType;
};

export const formatSegmentationSliceThicknessType = (
  segmentationSliceThicknessType: SegmentationSliceThicknessType,
): string => {
  let formattedSegmentationSliceThicknessType = '';
  switch (segmentationSliceThicknessType) {
    case SegmentationSliceThicknessType.LessThanOneMm:
      formattedSegmentationSliceThicknessType = '<1.00mm';
      break;
    case SegmentationSliceThicknessType.BetweenOneAndTwoMm:
      formattedSegmentationSliceThicknessType = '1.00mm - 2.00mm';
      break;
    case SegmentationSliceThicknessType.GreaterThanTwoMm:
      formattedSegmentationSliceThicknessType = '>2.00mm';
      break;
  }
  return formattedSegmentationSliceThicknessType;
};

export const formatSegmentationType = (segmentationType: SegmentationType): string => {
  let formattedSegmentationType = '';
  switch (segmentationType) {
    case SegmentationType.Automated:
      formattedSegmentationType = 'Automated';
      break;
    case SegmentationType.Manual:
      formattedSegmentationType = 'Manual';
      break;
  }
  return formattedSegmentationType;
};

export const formatPlanFeedbackType = (planFeedbackType: PlanFeedbackType): string => {
  let formattedPlanFeedbackType = '';
  switch (planFeedbackType) {
    case PlanFeedbackType.ImplantHeightSpecification:
      formattedPlanFeedbackType = 'Implant Height Specification';
      break;
    case PlanFeedbackType.ImplantFootprintSpecification:
      formattedPlanFeedbackType = 'Implant Footprint Specification';
      break;
    case PlanFeedbackType.LordoticSpecification:
      formattedPlanFeedbackType = 'Lordotic Specification';
      break;
    case PlanFeedbackType.KyphoticSpecification:
      formattedPlanFeedbackType = 'Kyphotic Specification';
      break;
    case PlanFeedbackType.AnatomicalConstraints:
      formattedPlanFeedbackType = 'Anatomical Constraints';
      break;
    case PlanFeedbackType.Other:
      formattedPlanFeedbackType = 'Other';
      break;
    case PlanFeedbackType.NoFeedback:
      formattedPlanFeedbackType = 'No Feedback';
      break;
  }
  return formattedPlanFeedbackType;
};
