import { CaseImagingLandmark, EndPlate, ImagingVertebralBody, Position } from '@workflow-nx/common';

export interface SerialImagingLandmark {
  x: number;
  y: number;
  id: string;
}

export const getEndPlatePosId = (endPlate: EndPlate, position: Position): string => {
  let endPlateStr: string;

  switch (endPlate) {
    case EndPlate.Superior:
      endPlateStr = 'S';
      break;
    case EndPlate.Inferior:
      endPlateStr = 'I';
      break;
  }

  let positionStr: string;

  switch (position) {
    case Position.Anterior:
      positionStr = 'A';
      break;
    case Position.Posterior:
      positionStr = 'P';
      break;
    case Position.PatientLeft:
      positionStr = 'L';
      break;
    case Position.PatientRight:
      positionStr = 'R';
      break;
    case Position.PosteriorEdge:
      positionStr = 'E';
      break;
  }

  return `${endPlateStr}${positionStr}`;
};
// NOTE - Should match format returned by Auto-XR
export const getPointId = (
  body: ImagingVertebralBody,
  endPlate: EndPlate,
  position: Position,
): string => {
  return `${body}_${getEndPlatePosId(endPlate, position)}`;
};

export const serializePoint = (data: CaseImagingLandmark): SerialImagingLandmark => {
  const id = getPointId(data.body, data.endPlate, data.position);

  return {
    x: data.x,
    y: data.y,
    id,
  };
};

// NOTE - Should match format returned by Auto-XR
export const deserializePoint = (data: SerialImagingLandmark): CaseImagingLandmark | null => {
  const { x, y, id } = data;

  const parts = id.split('_');

  if (parts.length !== 2) return null;

  const [bodyStr, placement] = parts;

  let body: ImagingVertebralBody | undefined = Object.values(ImagingVertebralBody).find(
    (v) => bodyStr === v,
  );

  if (!body) return null;

  const placementParts = placement.split('');

  if (placementParts.length !== 2) return null;

  const [endPlateStr, positionStr] = placementParts;

  let endPlate: EndPlate | undefined = undefined;

  switch (endPlateStr) {
    case 'S':
      endPlate = EndPlate.Superior;
      break;
    case 'I':
      endPlate = EndPlate.Inferior;
      break;
    default:
      break;
  }

  if (!endPlate) return null;

  let position: Position | undefined = undefined;

  switch (positionStr) {
    case 'A':
      position = Position.Anterior;
      break;
    case 'P':
      position = Position.Posterior;
      break;
    case 'R':
      position = Position.PatientRight;
      break;
    case 'L':
      position = Position.PatientLeft;
      break;
    default:
      break;
  }

  if (!position) return null;

  return {
    x,
    y,
    body,
    endPlate,
    position,
  };
};
